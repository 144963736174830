import {
  request
} from './request'
import qs from 'qs'


// /------------------------住宿订单-------------------/

// 住宿订单数据
export function getHotelOrder(params) {
  return request({
    url: '/admin/hotel-order',
    method: 'GET',
    params
  })
}
// 住宿订单详情
export function getHotelOrderDetail(params) {
  return request({
    url: '/admin/hotel-order/show',
    method: 'GET',
    params
  })
}
//确认住宿订单
export function confirmHotelOrder(params) {
  return request({
    url: '/admin/hotel-order/check',
    method: 'PUT',
    params
  })
}

//审核住宿订单
export function AuditHotelOrder(datas) {
  return request({
    url: '/admin/hotel-order/refund-check',
    method: 'PUT',
    data: qs.stringify(datas)
  })
}

//备注住宿订单
export function RemarkHotelOrder(datas) {
  return request({
    url: '/admin/hotel-order/remarks',
    method: 'PUT',
    data: qs.stringify(datas)
  })
}

//入住住宿订单
export function implementHotelOrder(params) {
  return request({
    url: '/admin/hotel-order/implement',
    method: 'PUT',
    params
  })
}

//拒绝住宿订单
export function RefuseHotelOrder(datas) {
  return request({
    url: '/admin/hotel-order/refund',
    method: 'PUT',
    data: qs.stringify(datas)
  })
}

// /------------------------旅游订单-------------------/
// 旅游订单数据
export function getTourOrder(params) {
  return request({
    url: '/admin/tour-order',
    method: 'GET',
    params
  })
}

//  旅游订单详情
export function getTourOrderDetail(params) {
  return request({
    url: '/admin/tour-order/show',
    method: 'GET',
    params
  })
}
//确认旅游订单
export function confirmTourOrder(params) {
  return request({
    url: '/admin/tour-order/check',
    method: 'PUT',
    params
  })
}

// /------------------------特权订单-------------------/
// 特权订单数据
export function getPrivilegeOrder(params) {
  return request({
    url: '/admin/privilege-order',
    method: 'GET',
    params
  })
}

// 特权订单数据 详情
export function getPrivilegeOrderDetail(params) {
  return request({
    url: '/admin/privilege-order/show',
    method: 'GET',
    params
  })
}
// /------------------------充值订单-------------------/
export function getuserOrderDetail(params) {
  return request({
    url: '/admin/user/wallet-order',
    method: 'GET',
    params
  })
}
